<template>
  <div id="surgicalElectrodeFactoryReport">
    <el-dialog
      :title="surgicalElectrodeFactoryReportFormTitle"
      width="1200px"
      :visible.sync="surgicalElectrodeFactoryReportDialogVisible"
      :close-on-click-modal="false"
      @close="surgicalElectrodeFactoryReportDialogClose"
    >
      <div id="pdfDom">
        <h1 align="center">一次性使用手术电极出厂检验报告</h1>
        <el-form
          ref="surgicalElectrodeFactoryReportFormRef"
          :model="surgicalElectrodeFactoryReportForm"
          :rules="surgicalElectrodeFactoryReportFormRules"
          label-position="right"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="编号" prop="formNo">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.formNo"
                  placeholder="请输入编号"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品名称" prop="productName">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.productName"
                  readonly
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="规格型号" prop="spec">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.spec"
                  placeholder="请输入规格型号"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="生产批号" prop="productionBatchNo">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.productionBatchNo"
                  placeholder="请输入生产批号"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="灭菌批号" prop="sterilizeBatchNo">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.sterilizeBatchNo"
                  placeholder="请输入灭菌批号"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="数量" prop="quantity">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.quantity"
                  placeholder="请输入数量"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="抽检数量" prop="inspectionQuantity">
                <el-input
                  v-model="
                    surgicalElectrodeFactoryReportForm.inspectionQuantity
                  "
                  placeholder="请输入抽检数量"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报告日期" prop="reportDate">
                <el-date-picker
                  v-model="surgicalElectrodeFactoryReportForm.reportDate"
                  placeholder="请选择报告日期"
                  value-format="yyyy-MM-dd"
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检验依据" prop="testBased">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.testBased"
                  placeholder="请输入检验依据"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="reportDetailList"
              :edit-config="{ trigger: 'click', mode: 'cell' }"
            >
              <vxe-table-column
                field="claim"
                title="要求条款"
                width="80"
                :content-render="{ name: '$input' }"
              />
              <vxe-table-column
                field="inspectionItem"
                title="检验项目"
                width="150"
                :content-render="{ name: '$input' }"
              />
              <vxe-table-column
                field="standard"
                title="标准要求"
                :content-render="{ name: '$input' }"
              />
              <vxe-table-column
                field="sampleQuantity"
                title="抽样数"
                width="100"
                :edit-render="{
                  name: '$input',
                  props: { type: 'number', clearable: true },
                }"
              />
              <vxe-table-column
                field="result"
                title="实测结果"
                width="120"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
            </vxe-table>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="检验结论" prop="conclusion">
                <el-radio-group
                  v-model="surgicalElectrodeFactoryReportForm.conclusion"
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                >
                  <el-radio :label="1"> 合格 </el-radio>
                  <el-radio :label="0"> 不合格 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="备注" prop="remarks">
                <el-input
                  v-model="surgicalElectrodeFactoryReportForm.remarks"
                  placeholder="请输入备注"
                  clearable
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="复核结果" prop="reviewResult">
                <el-radio-group
                  v-model="surgicalElectrodeFactoryReportForm.reviewResult"
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '复核一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                >
                  <el-radio :label="1"> 通过 </el-radio>
                  <el-radio :label="2"> 不通过 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批准结果" prop="approvalResult">
                <el-radio-group
                  v-model="surgicalElectrodeFactoryReportForm.approvalResult"
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '批准一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                >
                  <el-radio :label="1"> 通过 </el-radio>
                  <el-radio :label="2"> 不通过 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="检验记录" prop="scheduleInspectionRecord">
                <div
                  v-if="
                    surgicalElectrodeFactoryReportFormTitle ===
                    '一次性使用手术电极出厂检验报告详情'
                  "
                  class="el-textarea__inner"
                  v-html="
                    surgicalElectrodeFactoryReportForm.scheduleInspectionRecord
                  "
                ></div>
                <el-input
                  v-else
                  v-model="
                    surgicalElectrodeFactoryReportForm.scheduleInspectionRecord
                  "
                  placeholder="请输入检验记录"
                  type="textarea"
                  :rows="12"
                  :disabled="
                    surgicalElectrodeFactoryReportFormTitle !==
                      '新增一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '修改一次性使用手术电极出厂检验报告' &&
                    surgicalElectrodeFactoryReportFormTitle !==
                      '一次性使用手术电极出厂检验报告详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="surgicalElectrodeFactoryReportDialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="surgicalElectrodeFactoryReportFormSubmit"
        >
          确 定
        </el-button>
        <el-button
          v-if="
            surgicalElectrodeFactoryReportFormTitle ===
            '一次性使用手术电极出厂检验报告详情'
          "
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="生产批号">
        <el-input
          v-model="searchForm.productionBatchNo"
          placeholder="请输入生产批号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['OPERATION_OUT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="surgicalElectrodeFactoryReportPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="productionBatchNo" label="生产批号" />
      <el-table-column prop="sterilizeBatchNo" label="灭菌批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column prop="testBased" label="检验依据" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{
            scope.row.reportDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{
            scope.row.inspectionDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{
            scope.row.reviewDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{
            scope.row.approvalDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="scheduleInspectionRecord" label="检验记录" /> -->
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['OPERATION_OUT_DELETE']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['OPERATION_OUT_UPDATE']) ||
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['OPERATION_OUT_REVIEW']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="
              checkPermission(['OPERATION_OUT_APPROVAL']) &&
              scope.row.status === 2
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="surgicalElectrodeFactoryReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSurgicalElectrodeFactoryReport,
  deleteSurgicalElectrodeFactoryReport,
  updateSurgicalElectrodeFactoryReport,
  selectSurgicalElectrodeFactoryReportInfo,
  selectSurgicalElectrodeFactoryReportList,
  reviewSurgicalElectrodeFactoryReport,
  approvalSurgicalElectrodeFactoryReport,
} from "@/api/quality/surgicalElectrodeFactoryReport";
import moment from "moment";
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      surgicalElectrodeFactoryReportDialogVisible: false,
      surgicalElectrodeFactoryReportFormTitle: "",
      surgicalElectrodeFactoryReportForm: {
        id: "",
        formNo: "",
        productName: "一次性使用手术电极",
        spec: "",
        productionBatchNo: "",
        sterilizeBatchNo: "",
        quantity: "",
        inspectionQuantity: "",
        testBased: "",
        reportDate: "",
        conclusion: "",
        remarks: "",
        reviewResult: "",
        approvalResult: "",
        scheduleInspectionRecord: "",
        status: "",
        reportDetailJson: "",
        taskId: "",
      },
      surgicalElectrodeFactoryReportFormRules: {
        productionBatchNo: [
          {
            required: true,
            message: "生产批号不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      surgicalElectrodeFactoryReportPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productionBatchNo: "",
      },
      reportDetailList: [
        {
          claim: "1.3",
          inspectionItem: "尺寸",
          standard: "符合一次性使用手术电极产品技术要求表1",
          result: "",
        },
        {
          claim: "2.3.1",
          inspectionItem: "外观",
          standard: "手术电极的电极头应无明显机械损伤、无污物、无毛刺",
          result: "",
        },
        {
          claim: "2.3.2",
          inspectionItem: "外观",
          standard: "塑料柄部表面应无污物，无裂缝、无毛刺。功能指示字样应清晰",
          result: "",
        },
        {
          claim: "2.3.3",
          inspectionItem: "外观",
          standard:
            "手术电极具有一个以上插脚的手术连接器应有固定的脚距，禁止“飞线”连接",
          result: "",
        },
        {
          claim: "2.4.1",
          inspectionItem: "插拔",
          standard:
            "手术电极的电极头应可更换。更换手术电刀笔刀头时，用手应能插拨",
          result: "",
        },
        {
          claim: "2.4.2",
          inspectionItem: "按钮开关",
          standard:
            "手控型手术电极指揿按钮开关压弹时，工作正常、无卡滞现象。脚控型手术电极，其电凝、电切功能由高频电刀主机上的脚踏开关控制",
          result: "",
        },
        {
          claim: "2.4.3",
          inspectionItem: "功能指示",
          standard:
            "手控型手术电极靠近电极头的指揿按钮应为黄色按钮“CUT”（电切）。远离电极头的指揿按钮应为蓝色按钮“COAG”（电凝）",
          result: "",
        },
        {
          claim: "2.4.4",
          inspectionItem: "电缆线的连接牢固度",
          standard:
            "手术电极电缆线连接处应能承受40N拉力，历时15秒,不得有拉脱现象",
          result: "",
        },
        {
          claim: "2.4.5",
          inspectionItem: "电缆线长度",
          standard: "手术电极电缆线的外露长度≥2.0m",
          result: "",
        },
        {
          claim: "2.5.2",
          inspectionItem: "安全",
          standard:
            "手术电极的所用绝缘应能承受1.2倍的额定附件电压（2000v）的高频电压",
          result: "",
        },
        {
          claim: "2.7",
          inspectionItem: "无菌",
          standard: "手术电极经过确认后的灭菌过程，产品应无菌",
          result: "",
        },
        {
          claim: "2.8",
          inspectionItem: "环氧乙烷残留量",
          standard:
            "手术电极若采用环氧乙烷灭菌,则环氧乙烷残留量不得大于10mg/kg",
          result: "",
        },
      ],
    };
  },
  created() {
    selectSurgicalElectrodeFactoryReportList(this.searchForm).then((res) => {
      this.surgicalElectrodeFactoryReportPage = res;
    });
  },
  methods: {
    surgicalElectrodeFactoryReportDialogClose() {
      this.$refs.surgicalElectrodeFactoryReportFormRef.resetFields();
      this.reportDetailList = [
        {
          claim: "1.3",
          inspectionItem: "尺寸",
          standard: "符合一次性使用手术电极产品技术要求表1",
          result: "",
        },
        {
          claim: "2.3.1",
          inspectionItem: "外观",
          standard: "手术电极的电极头应无明显机械损伤、无污物、无毛刺",
          result: "",
        },
        {
          claim: "2.3.2",
          inspectionItem: "外观",
          standard: "塑料柄部表面应无污物，无裂缝、无毛刺。功能指示字样应清晰",
          result: "",
        },
        {
          claim: "2.3.3",
          inspectionItem: "外观",
          standard:
            "手术电极具有一个以上插脚的手术连接器应有固定的脚距，禁止“飞线”连接",
          result: "",
        },
        {
          claim: "2.4.1",
          inspectionItem: "插拔",
          standard:
            "手术电极的电极头应可更换。更换手术电刀笔刀头时，用手应能插拨",
          result: "",
        },
        {
          claim: "2.4.2",
          inspectionItem: "按钮开关",
          standard:
            "手控型手术电极指揿按钮开关压弹时，工作正常、无卡滞现象。脚控型手术电极，其电凝、电切功能由高频电刀主机上的脚踏开关控制",
          result: "",
        },
        {
          claim: "2.4.3",
          inspectionItem: "功能指示",
          standard:
            "手控型手术电极靠近电极头的指揿按钮应为黄色按钮“CUT”（电切）。远离电极头的指揿按钮应为蓝色按钮“COAG”（电凝）",
          result: "",
        },
        {
          claim: "2.4.4",
          inspectionItem: "电缆线的连接牢固度",
          standard:
            "手术电极电缆线连接处应能承受40N拉力，历时15秒,不得有拉脱现象",
          result: "",
        },
        {
          claim: "2.4.5",
          inspectionItem: "电缆线长度",
          standard: "手术电极电缆线的外露长度≥2.0m",
          result: "",
        },
        {
          claim: "2.5.2",
          inspectionItem: "安全",
          standard:
            "手术电极的所用绝缘应能承受1.2倍的额定附件电压（2000v）的高频电压",
          result: "",
        },
        {
          claim: "2.7",
          inspectionItem: "无菌",
          standard: "手术电极经过确认后的灭菌过程，产品应无菌",
          result: "",
        },
        {
          claim: "2.8",
          inspectionItem: "环氧乙烷残留量",
          standard:
            "手术电极若采用环氧乙烷灭菌,则环氧乙烷残留量不得大于10mg/kg",
          result: "",
        },
      ];
    },
    surgicalElectrodeFactoryReportFormSubmit() {
      if (
        this.surgicalElectrodeFactoryReportFormTitle ===
        "一次性使用手术电极出厂检验报告详情"
      ) {
        this.surgicalElectrodeFactoryReportDialogVisible = false;
        return;
      }
      this.$refs.surgicalElectrodeFactoryReportFormRef.validate(
        async (valid) => {
          if (valid) {
            if (
              this.surgicalElectrodeFactoryReportFormTitle ===
              "新增一次性使用手术电极出厂检验报告"
            ) {
              this.surgicalElectrodeFactoryReportForm.id = "";
              this.surgicalElectrodeFactoryReportForm.status = 1;
              this.surgicalElectrodeFactoryReportForm.reportDetailJson =
                JSON.stringify(this.$refs.xTable.getTableData().tableData);
              await addSurgicalElectrodeFactoryReport(
                this.surgicalElectrodeFactoryReportForm
              );
            } else if (
              this.surgicalElectrodeFactoryReportFormTitle ===
              "修改一次性使用手术电极出厂检验报告"
            ) {
              this.surgicalElectrodeFactoryReportForm.reportDetailJson =
                JSON.stringify(this.$refs.xTable.getTableData().tableData);
              await updateSurgicalElectrodeFactoryReport(
                this.surgicalElectrodeFactoryReportForm
              );
            } else if (
              this.surgicalElectrodeFactoryReportFormTitle ===
              "复核一次性使用手术电极出厂检验报告"
            ) {
              this.surgicalElectrodeFactoryReportForm.status = 2;
              await reviewSurgicalElectrodeFactoryReport(
                this.surgicalElectrodeFactoryReportForm
              );
            } else if (
              this.surgicalElectrodeFactoryReportFormTitle ===
              "批准一次性使用手术电极出厂检验报告"
            ) {
              this.surgicalElectrodeFactoryReportForm.status = 3;
              await approvalSurgicalElectrodeFactoryReport(
                this.surgicalElectrodeFactoryReportForm
              );
            }
            this.surgicalElectrodeFactoryReportPage =
              await selectSurgicalElectrodeFactoryReportList(this.searchForm);
            this.surgicalElectrodeFactoryReportDialogVisible = false;
          }
        }
      );
    },
    handleAdd() {
      this.surgicalElectrodeFactoryReportFormTitle =
        "新增一次性使用手术电极出厂检验报告";
      this.surgicalElectrodeFactoryReportDialogVisible = true;
      this.surgicalElectrodeFactoryReportForm.formNo =
        "CJ" + moment().format("YYYYMMDD") + "-";
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteSurgicalElectrodeFactoryReport(row.id);
        if (
          this.surgicalElectrodeFactoryReportPage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.surgicalElectrodeFactoryReportPage =
          await selectSurgicalElectrodeFactoryReportList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.surgicalElectrodeFactoryReportFormTitle =
        "修改一次性使用手术电极出厂检验报告";
      this.surgicalElectrodeFactoryReportDialogVisible = true;
      this.selectSurgicalElectrodeFactoryReportInfoById(row.id);
    },
    handleReview(index, row) {
      this.surgicalElectrodeFactoryReportFormTitle =
        "复核一次性使用手术电极出厂检验报告";
      this.surgicalElectrodeFactoryReportDialogVisible = true;
      this.selectSurgicalElectrodeFactoryReportInfoById(row.id);
    },
    handleApproval(index, row) {
      this.surgicalElectrodeFactoryReportFormTitle =
        "批准一次性使用手术电极出厂检验报告";
      this.surgicalElectrodeFactoryReportDialogVisible = true;
      this.selectSurgicalElectrodeFactoryReportInfoById(row.id);
    },
    handleInfo(index, row) {
      this.surgicalElectrodeFactoryReportFormTitle =
        "一次性使用手术电极出厂检验报告详情";
      this.surgicalElectrodeFactoryReportDialogVisible = true;
      this.selectSurgicalElectrodeFactoryReportInfoById(row.id);
    },
    selectSurgicalElectrodeFactoryReportInfoById(id) {
      selectSurgicalElectrodeFactoryReportInfo(id).then((res) => {
        this.surgicalElectrodeFactoryReportForm.id = res.id;
        this.surgicalElectrodeFactoryReportForm.formNo = res.formNo;
        this.surgicalElectrodeFactoryReportForm.productName = res.productName;
        this.surgicalElectrodeFactoryReportForm.spec = res.spec;
        this.surgicalElectrodeFactoryReportForm.productionBatchNo =
          res.productionBatchNo;
        this.surgicalElectrodeFactoryReportForm.sterilizeBatchNo =
          res.sterilizeBatchNo;
        this.surgicalElectrodeFactoryReportForm.quantity = res.quantity;
        this.surgicalElectrodeFactoryReportForm.inspectionQuantity =
          res.inspectionQuantity;
        this.surgicalElectrodeFactoryReportForm.testBased = res.testBased;
        this.surgicalElectrodeFactoryReportForm.reportDate = res.reportDate;
        this.surgicalElectrodeFactoryReportForm.conclusion = res.conclusion;
        this.surgicalElectrodeFactoryReportForm.remarks = res.remarks;
        this.surgicalElectrodeFactoryReportForm.reviewResult = res.reviewResult;
        this.surgicalElectrodeFactoryReportForm.approvalResult =
          res.approvalResult;
        if (this.surgicalElectrodeFactoryReportFormTitle == "一次性使用手术电极出厂检验报告详情") {
          this.surgicalElectrodeFactoryReportForm.scheduleInspectionRecord =
            res.scheduleInspectionRecord.replace(/\n/g, "<br>");
        } else {
          this.surgicalElectrodeFactoryReportForm.scheduleInspectionRecord =
            res.scheduleInspectionRecord;
        }

        this.surgicalElectrodeFactoryReportForm.status = res.status;
        this.surgicalElectrodeFactoryReportForm.taskId = res.taskId;
        this.reportDetailList = res.reportDetailList;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectSurgicalElectrodeFactoryReportList(this.searchForm).then((res) => {
        this.surgicalElectrodeFactoryReportPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectSurgicalElectrodeFactoryReportList(this.searchForm).then((res) => {
        this.surgicalElectrodeFactoryReportPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectSurgicalElectrodeFactoryReportList(this.searchForm).then((res) => {
        this.surgicalElectrodeFactoryReportPage = res;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.checkPermission(["OPERATION_OUT_REVIEW"]) && row.status === 1) {
        return "review";
      } else if (
        this.checkPermission(["OPERATION_OUT_APPROVAL"]) &&
        row.status === 2
      ) {
        return "review";
      }
      return "";
    },
    getPdf() {
      let loading = "";
      let dom = document.getElementById("pdfDom");
      html2PDF(dom, {
        jsPDF: {
          unit: "px",
          format: "a4",
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true,
        },
        imageType: "image/jpeg",
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0,
        },
        output: `一次性使用手术电极出厂检验报告${this.surgicalElectrodeFactoryReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: "pdf加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
        },
        success: function (pdf) {
          pdf.save(this.output);
          loading.close();
        },
      });
    },
  },
};
</script>

<style>
</style>
