import axios from '@/common/axios'
import qs from 'qs'

export function addSurgicalElectrodeFactoryReport (data) {
  return axios({
    method: 'post',
    url: '/quality/surgicalReport/add',
    data: qs.stringify(data)
  })
}

export function deleteSurgicalElectrodeFactoryReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/surgicalReport/delete/' + id
  })
}

export function updateSurgicalElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/surgicalReport/update',
    data: qs.stringify(data)
  })
}

export function selectSurgicalElectrodeFactoryReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/surgicalReport/info/' + id
  })
}

export function selectSurgicalElectrodeFactoryReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/surgicalReport/list',
    params: query
  })
}

export function reviewSurgicalElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/surgicalReport/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalSurgicalElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/surgicalReport/updateApprove',
    data: qs.stringify(data)
  })
}
